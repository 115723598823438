import * as React from "react";
import {FC} from "react";
import {Helmet} from "react-helmet-async";
import PageTitleWrapper from "components/PageTitleWrapper";
import {Button, Grid, Typography} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";

const PageHeader: FC<{ title: string, createButton?: { title: string } }> = ({title, createButton}) => {
  return (<>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTitleWrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {title}
            </Typography>
          </Grid>
          {createButton ? (
            <Grid item>
              <Button
                sx={{mt: {xs: 2, md: 0}}}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small"/>}
              >
                {createButton.title}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </PageTitleWrapper>
    </>
  );
}

export default PageHeader;
