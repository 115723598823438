import {useContext, useState} from "react";
import {HttpService} from "services/HttpService";
import {Severity} from "types/Toast";
import {ToastsContext} from "contexts/ToastsContext";
import {Button, FormControl, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import {NewSpace} from "../../../types/Space";
import Page from "../Common/Page";

function SpaceCreate() {
  return <Page title={"Create Space"}><SpaceForm/></Page>;
}

function SpaceForm() {
  const {setToasts} = useContext(ToastsContext);
  const initialFormValues = {title: "", prefix: null};
  const [formValues, setFormValues] = useState<NewSpace>(initialFormValues);
  const handleInputChange = (e) => {
    const {name, value} = e.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    HttpService.post<NewSpace>(
      "/user/space",
      formValues,
      () => {
        setFormValues(initialFormValues);
        setToasts(toasts => [
          ...toasts,
          {
            severity: Severity.success,
            key: 'create_space_success',
            message: 'Created successfully',
          }
        ]);
      },
      (error) => {
        setToasts(toasts => [
          ...toasts,
          {
            severity: Severity.error,
            key: 'create_space_error',
            message: error.message,
          }
        ]);
      }
    );
  };

  const defaultMinWidth = {
    sx: {minWidth: 300, m: 1}
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container alignItems="center" direction="column">
        <FormControl {...defaultMinWidth} variant="standard">
          <TextField
            required
            name="title"
            label="Title"
            placeholder="Title"
            value={formValues?.title}
            onChange={handleInputChange}
            variant="standard"
            fullWidth
          />
        </FormControl>
        <FormControl {...defaultMinWidth} variant="standard">
          <TextField
            required
            name="prefix"
            label="Prefix"
            placeholder="Prefix"
            value={formValues?.prefix || ""}
            onChange={handleInputChange}
            variant="standard"
            fullWidth
          />
        </FormControl>
        <Button {...defaultMinWidth} size="small" variant="outlined" type="submit">
          Create
        </Button>
      </Grid>
    </form>
  );
}

export default SpaceCreate;
