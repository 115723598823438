import {FC, forwardRef, SyntheticEvent, useCallback, useContext, useEffect, useState} from 'react';
import {Fade, Snackbar} from '@mui/material';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {Toast} from "types/Toast";
import {ToastsContext} from 'contexts/ToastsContext';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />);

const Toaster: FC = () => {
  const {setToasts, toasts} = useContext(ToastsContext);
  const [open, setOpen] = useState(false);
  const [currentToast, setCurrentToast] = useState<Toast>();

  useEffect(() => {
    if (toasts.length > 0 && !currentToast) {
      const toast = toasts[0];

      setCurrentToast({...toast});
      setToasts(currVal => currVal.filter(it => it.key !== toast.key));
      setOpen(true);
    }
  }, [toasts, currentToast, open, setCurrentToast, setToasts]);

  const handleClose = useCallback((_event: Event | SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    const updatedToast = undefined;

    setCurrentToast(updatedToast);
  }, []);

  return currentToast ? (
    <Snackbar
      TransitionComponent={Fade}
      TransitionProps={{onExited: handleExited}}
      anchorOrigin={currentToast.anchorOrigin || {vertical: 'top', horizontal: 'right'}}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={currentToast.severity} sx={{width: '400px'}}>
        {currentToast.message}
      </Alert>
    </Snackbar>
  ) : null;
};

export default Toaster;
