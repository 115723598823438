import {useRoutes} from 'react-router-dom';
import routes from 'routes';
import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {useKeycloak} from "@react-keycloak/web";
import Toaster from "content/applications/Common/Toaster";
import * as React from "react";
import {useEffect, useState} from "react";
import {HelmetProvider} from "react-helmet-async";
import {SidebarProvider} from "contexts/SidebarContext";
import {ToastsContextProvider} from "contexts/ToastsContext";
import {HttpService} from "services/HttpService";
import PermissionProvider from "./contexts/PermissionProvider";

function App() {
  const content = useRoutes(routes);
  const {keycloak} = useKeycloak();
  HttpService.configureHeaders();
  const [roles, setRoles] = useState(null)

  useEffect(() => {
    if (keycloak.authenticated) {
      HttpService.getRoles().then((response) => {
        setRoles(response)
      })
    }
  }, [keycloak.authenticated])
  return (
    keycloak.authenticated && roles ?
      <PermissionProvider employeeRoles={roles}>
        <HelmetProvider>
          <SidebarProvider>
            <ToastsContextProvider>
              <Toaster/>
              <ThemeProvider>
                <CssBaseline/>
                {content}
              </ThemeProvider>
            </ToastsContextProvider>
          </SidebarProvider>
        </HelmetProvider>
      </PermissionProvider> : null
  );
}

export default App;
