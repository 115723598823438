import ListTable from "content/applications/Common/ListTable";
import {HttpService} from "services/HttpService";
import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import {ToastsContext} from "contexts/ToastsContext";
import {Severity} from "types/Toast";
import {Scan} from "../../../types/Scan";
import {GridActionsCellItem, GridRowParams} from "@mui/x-data-grid";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {Button, DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ExportCustomerScanForm from "./ExportCustomerScanForm";

function Scans() {
  const {setToasts} = useContext(ToastsContext);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Scan[]>([])
  const [open, setOpen] = React.useState(false);
  const [reportId, setReportId] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    loadRows();
  }, []);

  const loadRows = useCallback(() => {
    HttpService.get<Scan[]>(
      `/scanner/scan/nessus`,
      (res) => {
        setRows(res.data)
      },
      (error) => {
        setToasts(toasts => [
          ...toasts,
          {
            severity: Severity.error,
            key: 'scan_get_error',
            message: error.message,
          }
        ]);
      },
      setLoading
    );
  }, []);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams) => {
        if (params.row.status === 'completed' || params.row.status === 'imported' || params.row.status === 'canceled') {
          return [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line react/jsx-key
            <GridActionsCellItem key={params.id} icon={<ExitToAppIcon/>} onClick={() => {
              setReportId(params.id);
              setOpen(true)
            }} label="Export"/>
          ]
        }
        return []
      }
    }
  ];

  return (<div>
    <ListTable rows={rows} rowCount={rows.length} columns={columns} loading={loading}/>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Profile</DialogTitle>
      <DialogContent>
        <ExportCustomerScanForm reportId={reportId} onSuccess={handleClose}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </div>);
}

export default Scans;
