import React from "react";

type PermissionContextType = {
  isAllowedTo: (permissions: string[]) => boolean;
}

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => false
}

const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;