import {useCallback, useRef, useState} from 'react';

import {Avatar, Box, Button, Divider, Hidden, lighten, Popover, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {UserService} from "services/UserService";
import {useKeycloak} from "@react-keycloak/web";

const UserBoxButton = styled(Button)(
  ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({theme}) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const user = UserService.getUser()

  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const avatar = <Avatar variant="rounded">{user.firstName[0]}{user.lastName[0]}</Avatar>;
  const {keycloak} = useKeycloak()
  const logout = useCallback(() => {
    keycloak.logout()
  }, [keycloak])
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {avatar}
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.username}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ml: 1}}/>
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{minWidth: 210}} display="flex">
          {avatar}
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.username}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{mb: 0}}/>
        <Divider/>
        <Box sx={{m: 1}}>
          <Button color="primary" onClick={logout} fullWidth>
            <LockOpenTwoToneIcon sx={{mr: 1}}/>
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
