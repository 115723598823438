import React, {FC} from 'react';
import PermissionContext from './PermissionContext';
import {includes, some} from "lodash";
import {KeyForDefaultPermissionGroup} from "../types/Employee";

export enum PermissionGroup { SuperAdmin = "SuperAdmin", SpaceAdmin = "SpaceAdmin" }
const PermissionProvider: FC<{ employeeRoles: KeyForDefaultPermissionGroup[] }> = ({employeeRoles, children}) => {
  const isAllowedTo = (roles: string[]) => some(roles, (role) => includes(employeeRoles, role));
  return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;