import {lazy, Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import {RouteObject} from 'react-router';
import SidebarLayout from 'layouts/SidebarLayout';
import SuspenseLoader from 'components/SuspenseLoader';
import SpaceForm from "./content/applications/SpaceList/SpaceForm";
import Restricted from "./contexts/Restricted";
import {PermissionGroup} from "./contexts/PermissionProvider";
import ScanList from "./content/applications/ScanList";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader/>}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import('content/pages/Status/Status404')));
const CustomerList = Loader(lazy(() => import('content/applications/CustomerList')));
const QuestionList = Loader(lazy(() => import('content/applications/QuestionList')));
const ControlList = Loader(lazy(() => import('content/applications/ControlList')));
const CatalogueList = Loader(lazy(() => import('content/applications/CatalogueList')));
const PartnerList = Loader(lazy(() => import('content/applications/PartnerList')));
const CustomerCreate = Loader(lazy(() => import('content/applications/CustomerCreate')));
const PartnerCreate = Loader(lazy(() => import('content/applications/PartnerCreate')));
const CatalogueImport = Loader(lazy(() => import('content/applications/CatalogueImport')));
const CustomerAdminEmployeeInvite = Loader(lazy(() => import('content/applications/CustomerEmployeeInvite')));
const PartnerAdminEmployeeInvite = Loader(lazy(() => import('content/applications/PartnerEmployeeInvite')));
const PartnerCustomerAssign = Loader(lazy(() => import('content/applications/PartnerCustomerAssign')));
const BlackList = Loader(lazy(() => import('content/applications/TargetBlackList')));
const TargetBlackListCreate = Loader(lazy(() => import('content/applications/TargetBlackListCreate')));
const ConceptTypeList = Loader(lazy(() => import('content/applications/ConceptTypeList')));
const ConceptTypeCreate = Loader(lazy(() => import('content/applications/ConceptType')));
const Space = Loader(lazy(() => import('content/applications/SpaceList')));
const SpaceStepper = Loader(lazy(() => import('content/applications/SpaceList/stepper')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout/>,
    children: [
      {
        path: '/',
        element: (<div>
          <Restricted to={[PermissionGroup.SuperAdmin]}><Navigate to="customer" replace/></Restricted>
          <Restricted to={[PermissionGroup.SpaceAdmin]}><Navigate to="space" replace/></Restricted>
        </div>),
      },
      {
        path: '*',
        element: <Status404/>
      },
      {
        path: 'customer',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><CustomerList/></Restricted>
          },
          {
            path: 'add',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><CustomerCreate/></Restricted>
          },
          {
            path: 'employee/invite',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><CustomerAdminEmployeeInvite/></Restricted>
          }
        ]
      },
      {
        path: 'partner',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><PartnerList/></Restricted>
          },
          {
            path: 'add',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><PartnerCreate/></Restricted>
          },
          {
            path: 'employee/invite',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><PartnerAdminEmployeeInvite/></Restricted>
          },
          {
            path: 'assign/customer',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><PartnerCustomerAssign/></Restricted>
          }
        ]
      },
      {
        path: 'catalogue',
        children: [
          {
            path: '',
            element: <Navigate to="import" replace/>
          },
          {
            path: 'import',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><CatalogueImport/></Restricted>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><CatalogueList/></Restricted>
          }
        ]
      },
      {
        path: 'control',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><ControlList/></Restricted>
          }
        ]
      },
      {
        path: 'question',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><QuestionList/></Restricted>
          }
        ]
      },
      {
        path: 'black-list',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'add',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><TargetBlackListCreate/></Restricted>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><BlackList/></Restricted>
          }
        ]
      },
      {
        path: 'concept-type',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'add',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><ConceptTypeCreate/></Restricted>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><ConceptTypeList/></Restricted>
          }
        ]
      },
      {
        path: 'space',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'add',
            element: <Restricted to={[PermissionGroup.SuperAdmin, PermissionGroup.SpaceAdmin]} redirect><SpaceForm/></Restricted>
          },
          {
            path: 'space-data',
            element: <Restricted to={[PermissionGroup.SuperAdmin, PermissionGroup.SpaceAdmin]} redirect><SpaceStepper/></Restricted>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin, PermissionGroup.SpaceAdmin]} redirect><Space/></Restricted>
          }
        ]
      },
      {
        path: 'scan',
        children: [
          {
            path: '',
            element: <Navigate to="list" replace/>
          },
          {
            path: 'list',
            element: <Restricted to={[PermissionGroup.SuperAdmin]} redirect><ScanList/></Restricted>
          }
        ]
      }
    ]
  }
];

export default routes;
