import {createContext, Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {Toast} from 'types/Toast';

export const ToastsContext = createContext<{ toasts: Toast[]; setToasts: Dispatch<SetStateAction<Toast[]>> }>({
  toasts: [],
  setToasts: () => void 0
});

export const ToastsContextProvider: FC = ({children}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const value = useMemo(() => ({toasts, setToasts}), [toasts]);

  return <ToastsContext.Provider value={value}>{children}</ToastsContext.Provider>;
};
