import React, {useContext} from "react";
import PermissionContext from "./PermissionContext";
import Status403 from "../content/pages/Status/Status403";

const Restricted: React.FunctionComponent<{ to: string[]; redirect?: boolean }> = ({to, redirect, children}) => {
  const {isAllowedTo} = useContext(PermissionContext);
  if (isAllowedTo(to)) {
    return <>{children}</>;
  }

  return redirect ? <Status403/> : null;
};

export default Restricted;