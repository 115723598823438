import {SnackbarOrigin} from '@mui/material';

type Toast = {
  message: string;
  key: string;
  severity?: Severity;
  anchorOrigin?: SnackbarOrigin;
};

enum Severity { success = 'success', info = 'info', warning = 'warning', error = 'error' }

export {Severity};
export type {Toast};
