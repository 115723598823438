import * as React from "react";
import {FC, ReactNode} from "react";
import {Card, CardContent, Container, Grid} from "@mui/material";
import PageHeader from "content/applications/Common/PageHeader";
import Footer from "components/Footer";

const Page: FC<{ children?: ReactNode, title: string }> = ({children, title}) => {
  return (
    <>
      <PageHeader title={title}/>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer/>
    </>
  );
}

export default Page;
