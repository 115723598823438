import 'nprogress/nprogress.css';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {keycloak} from "Keycloak";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import {BrowserRouter} from "react-router-dom";

function Welcome() {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={{onLoad: 'login-required'}}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

ReactDOM.render(<Welcome/>, document.getElementById('root'));
