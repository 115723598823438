import {HttpService} from "services/HttpService";
import * as React from "react";
import {FC, SyntheticEvent, useCallback, useContext} from "react";
import {ToastsContext} from "contexts/ToastsContext";
import {Severity} from "types/Toast";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {Customer} from "../../../types/Customer";
import {CompanyIdentifier} from "../../../types/Identifier";

const ExportCustomerScanForm: FC<{ reportId: string, onSuccess: () => void }> = ({reportId, onSuccess}) => {
  const {setToasts} = useContext(ToastsContext);
  const [customerId, setCustomerId] = React.useState<string>(null);

  const customers = HttpService.getPaged<Customer>(
    `/user/customer`,
    (error) => {
      setToasts(toasts => [
        ...toasts,
        {
          severity: Severity.error,
          key: 'customer_get_error',
          message: error?.response?.data?.message || error.message,
        }
      ]);
    }
  ).content;

  const exportReport = useCallback(
    (customerId: string) => {
      HttpService.post(
        `/scanner/company/${customerId}/scan/${reportId}/export`,
        null,
        () => {
          setToasts(toasts => [
            ...toasts,
            {
              severity: Severity.success,
              key: 'export_report_success',
              message: 'Export successfully',
            }
          ])
        },
        (error) => {
          setToasts(toasts => [
            ...toasts,
            {
              severity: Severity.error,
              key: 'export_report_error',
              message: error?.response?.data?.message || error.message,
            }
          ]);
        }
      );
    }, [reportId],
  );

  const defaultMinWidth = {
    sx: {minWidth: 300, m: 1}
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    exportReport(customerId);
    onSuccess();
  };

  return <form onSubmit={handleSubmit}>
    <Grid container alignItems="center" direction="column">
      <Autocomplete
        {...defaultMinWidth}
        disableClearable
        multiple={false}
        options={customers.map((customer) => ({id: customer.id, title: customer.title}))}
        renderInput={(params) => <TextField {...params} variant="standard" required label="Customer" name="customerId"/>}
        isOptionEqualToValue={(option: CompanyIdentifier, value: CompanyIdentifier) => option.id === value.id}
        getOptionLabel={(option: CompanyIdentifier) => option.title}
        onChange={(_event: SyntheticEvent, value: CompanyIdentifier) => {
          setCustomerId(value.id);
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        )}
      />
      <Button {...defaultMinWidth} size="small" variant="outlined" type="submit">
        Export
      </Button>
    </Grid>
  </form>;
}

export default ExportCustomerScanForm;
