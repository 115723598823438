export type Page<T> = {
  content: T[];
  number: number;
  totalElements: number;
  totalPages: number;
}

export const EmptyPage = <T>(): Page<T> => ({
  content: [],
  number: 0,
  totalElements: 0,
  totalPages: 0,
})
