import * as React from 'react';
import {FC, useCallback} from 'react';
import {DataGrid, GridCellParams, GridToolbar} from "@mui/x-data-grid";
import {DataGridProps} from "@mui/x-data-grid/models/props/DataGridProps";
import {LinearProgress, Tooltip} from "@mui/material";

const ListTable: FC<DataGridProps> = (props) => {
    const columns = useCallback(() => props.columns.map((column) => {
        if (column?.renderCell === undefined && column.field !== 'actions') {
            return ({
                ...column,
                renderCell: (params: GridCellParams) => <Tooltip title={params.formattedValue}>
                    <span>{params.formattedValue}</span>
                </Tooltip>
            })
        } else {
            return column
        }
    }), [props.columns]);

    return <DataGrid
        experimentalFeatures={{newEditingApi: false}}
        autoHeight={true}
        loading={false}
        components={{
            Toolbar: GridToolbar,
            LoadingOverlay: LinearProgress,
        }}
        {...props}
        columns={columns()}
    />
};

export default ListTable;
