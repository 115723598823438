import React, {useState} from 'react';
import {ThemeProvider} from '@mui/material';
import {themeCreator} from 'theme/base';
import {StylesProvider} from '@mui/styles';

export const ThemeContext = React.createContext({});

const ThemeProviderWrapper: React.FC = (props) => {
  const [themeName, _setThemeName] = useState('PureLightTheme');
  const theme = themeCreator(themeName);

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={_setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
