import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from "axios";
import {configure} from "axios-hooks";
import {EmptyPage, Page} from "types/Page";
import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {KeyForDefaultPermissionGroup} from "../types/Employee";
import {PermissionGroupAssignment} from "../types/PermissionGroupAssignment";

const baseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_URL
const _axios = axios.create({
  baseURL: baseUrl,
  headers: {
    accept: 'application/json',
    contentType: 'application/json',
  }
});

configure({axios: _axios});

const configureHeaders = () => {
  const {keycloak} = useKeycloak();
  _axios.interceptors.request.use((config: AxiosRequestConfig) => {
    return new Promise((resolve) => {
      const cb = () => {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
        resolve(config);
      };
      keycloak.updateToken(5)
        .then(cb)
        .catch(keycloak.login)
    });
  });
};

async function getRoles(): Promise<KeyForDefaultPermissionGroup[]> {
  return _axios.get<PermissionGroupAssignment[]>('/user/permission-group-assignment').then(res => {
    return res.data.map((it) => {
      return it.permissionGroup.keyForDefault
    })
  })
}

function getPaged<T>(url: string, onError?: (error: AxiosError<{ message: string }>) => void, onSuccess?: (res: AxiosResponse<Page<T>>) => void, setLoading?: (boolean) => void): Page<T> {
  const [items, setItems] = useState(EmptyPage<T>());
  useEffect(() => {
    getAllItems()
  }, []);
  const getAllItems = () => {
    setLoading?.(true);
    _axios.get<Page<T>>(url)
      .then(res => {
        setItems(res.data)
        onSuccess?.(res)
      })
      .catch(onError)
      .finally(() => setLoading?.(false));
  }
  return items;
}

function post<T>(url: string, data?: T, onSuccess?: (res: AxiosResponse<T>) => void, onError?: (error: AxiosError<{ message: string }>) => void, setLoading?: (boolean) => void): void {
  setLoading?.(true);
  _axios.post(url, data)
    .then(onSuccess)
    .catch(onError)
    .finally(() => setLoading?.(false));
}

function get<T>(url: string, onSuccess?: (res: AxiosResponse<T>) => void, onError?: (error: AxiosError<{ message: string }>) => void, setLoading?: (boolean) => void): void {
  setLoading?.(true);
  _axios.get(url)
    .then(onSuccess)
    .catch(onError)
    .finally(() => setLoading?.(false));
}

function request<T, R>(url: string, method: Method, data?: T, onSuccess?: (res: AxiosResponse<R>) => void, onError?: (error: AxiosError<{ message: string }>) => void, setLoading?: (boolean) => void): void {
  setLoading?.(true);
  _axios.request({
    url: url,
    method: method,
    data: data
  })
    .then(onSuccess)
    .catch(onError)
    .finally(() => setLoading?.(false));
}

function put<T, R>(url: string, data?: T, onSuccess?: (res: AxiosResponse<R>) => void, onError?: (error: AxiosError) => void, setLoading?: (boolean) => void): void {
  setLoading?.(true);
  _axios.put(url, data)
    .then(onSuccess)
    .catch(onError)
    .finally(() => setLoading?.(false));
}

function remove<T>(url: string, onSuccess?: (res: AxiosResponse<T>) => void, onError?: (error: AxiosError<{ message: string }>) => void, setLoading?: (boolean) => void): void {
  setLoading?.(true);
  _axios.delete(url)
    .then(onSuccess)
    .catch(onError)
    .finally(() => setLoading?.(false));
}

const HttpService = {
  configureHeaders,
  getPaged,
  post,
  get,
  request,
  put,
  remove,
  getRoles
};

export {HttpService};
