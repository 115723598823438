import {User} from "types/User";
import {keycloak} from "Keycloak";

const _kc = keycloak;

const getUser: () => User = () => {
  const firstName = _kc.tokenParsed?.given_name || "";
  const lastName = _kc.tokenParsed?.family_name || "";
  return {
    username: _kc.tokenParsed?.preferred_username || "",
    firstName: firstName,
    lastName: lastName,
    fullName: `${firstName} ${lastName}`
  }
}

const UserService = {
  getUser
};

export {UserService};
