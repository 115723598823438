import {Box, Container, Typography} from '@mui/material';
import {Helmet} from 'react-helmet-async';

import {styled} from '@mui/material/styles';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Status403() {
  return (
    <>
      <Helmet>
        <title>Status - 403</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Typography variant="h2" sx={{my: 2}}>
              Do not have permission to view this page.
            </Typography>
          </Box>
        </Container>
      </MainContent>
    </>
  );
}

export default Status403;
